import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserService } from '../../../@core/data/users.service';
import { UserInfoService } from '../../../@core/info/userinfo.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  @Input() position = 'normal';

  user: any;

  userMenu = [{ title: '修改密码', link: '/pages/users/change-password'}, { title: '登出' , link: '/auth/logout'}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private userInfoService: UserInfoService,
              private analyticsService: AnalyticsService,
              private authService: NbAuthService) {

                this.authService.onTokenChange()
                  .subscribe((token: NbAuthJWTToken) => {
                    if (token.isValid()) {
                      this.user = token.getPayload();
                    }
                  });
              }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.nick);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  ngOnDestroy() {
    this.userInfoService.clearAllinfo();
  }
}
