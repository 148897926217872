import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Observable} from 'rxjs';

@Injectable()
export class UserInfoService {
  private userName: string;
  private token: string;
  private role: string;

  constructor(private http: HttpClient,
              private authService: NbAuthService) {
    this.getUserInfo();
  }

  getUserInfo() {
    this.authService.getToken().subscribe((token: NbAuthJWTToken) => {
      this.userName = token.getPayload()['username'];
      this.token = token.getValue();
      this.role = token.getPayload()['role'];
    })
  }

  checkUsername() {
    this.getUserInfo();
    return Observable.create(observer => {
      const interval_id = setInterval(() => {
        if (this.userName) {
          observer.next(this.userName);
          observer.complete();
          clearInterval(interval_id);
        }
      }, 100);
    });
  }

  getUserName() {
    return this.userName;
  }

  getToken() {
    return this.token;
  }

  getRole() {
    return this.role;
  }


  clearAllinfo() {
    this.token = null;
    this.userName = null;
    this.role = null;
  }
}

