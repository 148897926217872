import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {UserInfoService} from './userinfo.service';
import {Observable} from 'rxjs';

@Injectable()
export class UserInfoResolveService implements Resolve<any> {
    constructor (private userInfoService: UserInfoService) { }

    resolve(): Observable<any> {
        return this.userInfoService.checkUsername();
    }
}
